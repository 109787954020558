<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div style="text-align:center; margin-top: 10rem">
      <p class="mb-0 font-size-18"><b>Não autorizado</b></p>
      <span> Você não tem autorização para acessar a página solicitada.</span>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'

/**
 * Dashboard Component
 */
export default {
  page: {
    title: 'Permissão Negada',
    meta: [
      {
        name: 'Permissão negada - Vocẽ não tem autorização para acessar essa página',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader
  },
  data () {
    return {
      title: '',
      items: []
    }
  }
}
</script>
